import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { PLANS_ARRAY } from '../constants/paymentConstants'
import '../styles/Plan.scss'
import { useSelector, useDispatch } from 'react-redux'
import { setFreeplan } from '../app/actions/authActions'
import toast from 'react-hot-toast'
import DeleteModal from '../components/Modals/DeleteModal'

const Plan = () => {

    const dispatch = useDispatch()
    const redirect = useNavigate()

    const [choice, setChoice] = useState("")
    const [isYearlyPlan, setIsYearlyPlan] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)


    const { user } = useSelector(state => state.Auth)

    // const CapsOnlyFirstLetter = (string) => {
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    // }


    useEffect(() => {
        setChoice(user?.planId?.toString().split('-')[1]?.toUpperCase())
        if (user?.planId?.toString().split('-')[2]?.toUpperCase() === 'YEARLY') {
            setIsYearlyPlan(true)
        }
        else {
            setIsYearlyPlan(false)
        }
        // eslint-disable-next-line
    }, [choice])


    const redirectToDashboard = () => {
        redirect('/dashboard')
    }

    const handleOnDelete = () => {
        dispatch(setFreeplan(redirectToDashboard))
        setChoice("Bronze")
    }



    // useEffect(() => {

    //     if (user.planId !== 'free') {
    //         setChoice(CapsOnlyFirstLetter(user.planId.split('-')[1]))
    //         setIsYearlyPlan(user.planId.split('-')[2] === 'yearly')
    //     }
    //     // eslint-disable-next-line
    // }, [])

    const PaymentCard = ({
        NAME,
        MONTHLY_PRICE,
        YEARLY_PRICE,
        BULLET_POINTS,
    }) => {
        return (
            <>
                {
                    NAME === 'Bronze' ? (
                        <div
                            onClick={() => setDeleteModal(true)}
                            className={`payment-card${NAME.toUpperCase() === choice ? " payment-card--selected" : ""}`}
                        >
                            <div className="d-flex mt-2 mb-2">
                                <div className="d-flex align-items-center">
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id={`payent-radio-${NAME}`}
                                            onChange={() => setChoice(NAME)}
                                            checked={NAME === choice}
                                        />
                                        <label
                                            htmlFor={`payent-radio-${NAME}`}
                                            className="radio-label"
                                        ></label>
                                    </div>
                                    <div>
                                        <h2 className="m-0 mb-4">{NAME}</h2>
                                        <div className="d-flex align-items-center">
                                            <h1 className="payment-card__price">
                                                ${isYearlyPlan ? YEARLY_PRICE : MONTHLY_PRICE}
                                            </h1>
                                            <span className="payment-card__label">
                                                /{isYearlyPlan ? " Year" : " Month"}
                                            </span>
                                        </div>

                                    </div>
                                </div>


                                {/* <span className="payment-card__disclaimer">
                        Automatically billed {isYearlyPlan ? " yearly" : " monthly"}
                    </span> */}
                            </div>
                            <div className="payment-card__plan-card">

                                <ul className="payment-card__list mb-4">
                                    {BULLET_POINTS?.map((point, index) => (
                                        <li key={index} className="payment-card__list-item">
                                            <i className={`bx ${NAME === choice ? 'bxs-check-circle' : 'bx-check-circle'} bullet-check`}></i>
                                            {point}
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div>
                    ) : (<Link to={`/payment?plan=${NAME.toLowerCase()}&yearly=${isYearlyPlan}`} >
                        <div
                            onClick={() => setChoice(NAME)}
                            className={`payment-card${NAME.toUpperCase() === choice ? " payment-card--selected" : ""}`}>
                            <div className="d-flex mt-2 mb-2">
                                <div className="d-flex align-items-center">
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id={`payent-radio-${NAME}`}
                                            onChange={() => setChoice(NAME)}
                                            checked={NAME === choice}
                                        />
                                        <label
                                            htmlFor={`payent-radio-${NAME}`}
                                            className="radio-label"
                                        ></label>
                                    </div>
                                    <div>
                                        <h2 className="m-0 mb-4">{NAME}</h2>
                                        <div className="d-flex align-items-center">
                                            <h1 className="payment-card__price">
                                                ${isYearlyPlan ? YEARLY_PRICE : MONTHLY_PRICE}
                                            </h1>
                                            <span className="payment-card__label">
                                                /{isYearlyPlan ? " Year" : " Month"}
                                            </span>
                                        </div>

                                    </div>
                                </div>


                                {/* <span className="payment-card__disclaimer">
                            Automatically billed {isYearlyPlan ? " yearly" : " monthly"}
                        </span> */}
                            </div>
                            <div className="payment-card__plan-card">

                                <ul className="payment-card__list mb-4">
                                    {BULLET_POINTS?.map((point, index) => (
                                        <li key={index} className="payment-card__list-item">
                                            <i className={`bx ${NAME === choice ? 'bxs-check-circle' : 'bx-check-circle'} bullet-check`}></i>
                                            {point}
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div>
                    </Link >)

                }

            </>
        );
    };


    return (
        <>
            <div className="payment">
                <div className="payment__container">
                    <div className="d-flex justify-content-between">
                        <div className='d-flex'>
                            <Link to='/profile'>
                                <i className='bx bx-chevron-left back-btn'></i>
                            </Link>
                            <h1 className="mb-5 ">Choose your plan</h1>
                        </div>
                        <div>
                            {user.planId !== 'free' && <div className='plan'>Current Plan: <span className='current-plan'>{`${user?.planId?.toString().split('-')[1]?.toUpperCase()} (${user?.planId?.toString().split('-')[2]?.toUpperCase()})`}</span></div>}
                        </div>
                    </div>

                    <div
                        className={`payment__switch${isYearlyPlan ? " payment__switch--yearly" : ""
                            }`}
                    >
                        <button
                            className="payment__switch-button"
                            onClick={() => setIsYearlyPlan((prev) => !prev)}
                        >
                            <span className="payment__switch-monthly">Monthly</span>
                            <span className="payment__switch-yearly">Yearly</span>
                        </button>
                        <div className="payment__switch-slider"></div>
                    </div>

                    <div className="payment__cards-container">
                        <div className="payment__cards">
                            {PLANS_ARRAY?.map((item) => (
                                <PaymentCard key={item.NAME} {...item} />
                            ))}
                        </div>
                    </div>
                    <h6 style={{ textAlign: 'left', fontWeight: 600, marginTop: '15px' }}>* capped at 30 forms per month</h6>

                </div>
            </div>
            <DeleteModal onClose={() => setDeleteModal(false)} isOpen={deleteModal} onDelete={handleOnDelete} />
        </>
    )
}

export default Plan
import React from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getAssessments, requestReviewReport } from '../../app/actions/assessActions';
import { getUserToken } from '../../app/actions/authActions';
import axios from 'axios';

const PendingReportList = () => {
    const { assessments } = useSelector(state => state.Assess)
    const { user } = useSelector(state => state.Auth)
    const dispatch = useDispatch();
    const API_URL = process.env.REACT_APP_API_HOST;

    const columns = [
        {
            name: 'Date',
            selector: row => moment(row.date).format('DD-MM-YYYY'),
            sortable: true,
        },
        {
            name: 'Assessment Forms',
            selector: row => row.assessmentForms,
            sortable: true,
            width: '300px'
        },
        {
            name: 'Assessment Status',
            sortable: true,
            cell: row => <div className='d-flex'>
                {
                    row.status === 'pending' ? <span className='badge badge-warning'>Pending</span> : <span className='badge badge-success'>Completed</span>
                }
            </div>,
            center: 'true'
        },
        {
            name: 'Report Status',
            sortable: true,
            cell: row => <div className='d-flex'>
                {
                    row.reviewReport === true ? <>
                        {
                            (row.reviewReport === true && row.status === 'completed' && row.reportStatus === 'approved') ? <span className='badge badge-success'>Ready</span> : <span className='badge badge-warning'>Pending</span>
                        }
                    </> : <>
                        {
                            row.status === 'completed' ? <span className='badge badge-success'>Ready</span> : <span className='badge badge-warning'>Pending</span>
                        }</>
                }
            </div>,
            center: 'true'
        },
        {
            name: 'Actions',
            cell: row => row.status === 'completed' ? <>
                {
                    row.reviewReport === true ? <>
                        {row.reportStatus === 'approved' && <i className='bx bx-arrow-to-bottom download-icon' onClick={() => downloadAssessmentReport(row.id)}></i>}
                    </> : <>
                        <i className='bx bx-arrow-to-bottom download-icon' onClick={() => downloadAssessmentReport(row.id)}></i></>
                }
                {
                    (user.planId === 'assess-gold-monthly-patient' || user.planId === 'assess-gold-yearly-patient') && user.reviewCount === 0 && (row.doctorId === undefined || row.doctorId === null) ? <button className="btn btn-info btn-pro btn-sm mx-3" onClick={() => { reviewReport(row.id) }}>Request Review</button> : <>
                        {
                            (row.reviewReport === true && (row.doctorId === undefined || row.doctorId === null)) && <button className="btn btn-info btn-pro btn-sm mx-3" disabled>Review Requested</button>
                        }
                    </>
                }
            </>
                : row.status === 'completed' ? <></> : <a href={`https://frontend-dev.cliniscripts.com/assessment/${row.id}`}><button className="btn btn-primary btn-pro btn-sm">Take Assessment</button></a>,
            sortable: false,
            center: 'true'
        }
    ];

    const data = assessments?.map(assessment => {
        return {
            id: assessment?._id,
            date: assessment?.createdAt,
            assessmentForms: assessment?.formIds?.filter(f => f.tier !== 'tier-1').map(form => form?.formName).join(', ').concat(', Tier 1'),
            status: assessment?.progress.status,
            reportStatus: assessment?.reportStatus,
            shortReport: assessment?.shortSummary ? assessment.shortSummary : 'No Report',
            doctorId: assessment?.doctorId,
            reviewReport: assessment?.patientRequestReview
        }
    })

    const reviewReport = (id) => {
        dispatch(requestReviewReport(id))
        setTimeout(() => {
            dispatch(getUserToken())
            dispatch(getAssessments())
        }, 1000)
    }

    const removeUnderScore = (key) => {
        return key.replace(/_/g, ' ')
    }

    const downloadAssessmentReport = async (d) => {
        const assessment = assessments.find(assessment => assessment._id === d);
        const assessContent = assessment?.reportType === 'long' ? assessment?.longSummary : assessment?.shortSummary;

        const content = `
            <html>
                <head>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        h1 { text-align: center; }
                        p { margin: 0 0 10px; }
                        .heading-text { font-size: 18px; margin: 20px 0 5px;}
                    </style>
                </head>
                <body>
                    <h1>Psychological Assessment Report</h1>
                    <p>Name: ${assessment?.userDetails?.firstName} ${assessment?.userDetails?.lastName}</p>
                    <p>Date of Birth: ${assessment?.userDetails?.yearOfBirth}</p>
                    <p>Age: ${new Date().getFullYear() - assessment?.userDetails?.yearOfBirth}</p>
                    <p>Assessed By: ${user?.firstName} ${user?.lastName}</p>
                    <p>Assessment Date: ${new Date(assessment?.updatedAt).toLocaleDateString()}</p>
                    ${Object.entries(assessContent).map(([key, value]) => `
                        <p class="heading-text"><b>${removeUnderScore(key)}</b></p> <p>  ${Array.isArray(value) ? value.join('<br/>') : value}</p>
                    `).join('')}
                    <br>
                    ${assessment?.doctorId !== undefined && assessment?.doctorId !== null ? `<img src="${assessment?.doctorId?.signature}" alt="signature" style="width: 200px; height: 100px; margin: 15px 0 10px 0;">
                    <p>${assessment?.doctorId?.additional.replace(/\n/g, '<br>')}</p>` : ''}       
                </body>
            </html>
        `;

        const options = {
            format: 'A4',
            margin: {
                top: '0.5in',
                right: '0.5in',
                bottom: '0.5in',
                left: '0.5in'
            }
        };

        try {
            const response = await axios.post(`${API_URL}/generate-pdf`, { content, options }, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Assessment_Report.pdf';
            link.click();
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            console.log('PDF generated successfully');
        }
    };

    return (
        <div className="section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="d-flex justify-content-end">
                            {/* <button className="btn btn-primary">Create Report</button> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <DataTable columns={columns} data={data} pagination />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PendingReportList;